import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import window from "global/window";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import { incrementMetric } from "@src/utils/logClient";

export default function OIDCLogin() {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { issuer } = parsedLocation;

  useRedirectHandler(() => {
    if (issuer) {
      return `https://teach.classdojo.com/#/oidc/login/${issuer}`;
    }

    incrementMetric("external_site.universal_link.fallback", { tag: "oidclogin" });
    return "https://teach.classdojo.com/#/";
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
}
